<template>
  <v-dialog v-model="dialogActive" fullscreen>
    <template v-slot:activator="{ on: dialogOn }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn v-on="{ ...tooltipOn, ...dialogOn }">N</v-btn>
        </template>
        <span>Click to submit a summary for this chapel</span>
        <div v-if="tooltip">{{ tooltip }}</div>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar dark color="#193264" style="margin-bottom:16px">
        <v-btn icon dark @click="dialogActive = false">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Submit a Chapel Summary</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p>
          <b>Date: </b> {{ stringFormatDate(chapel.date) }}<br/>
          <b>Speaker: </b> {{ chapel.speaker }}<br/>
          <b v-if="chapel.title">Title: </b> {{ chapel.title }}
        </p>
        <html-editor v-model="summary" :small-editor="false" large-editor></html-editor>
        <p>Word count: {{ wordCount }}</p>
        <p>You have submitted {{ summaryCount }} summar{{ summaryCount === 1 ? 'y' : 'ies' }} this term, so your summary must be at least {{ summaryMinWords }} words long.</p>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="wordCount < summaryMinWords" color="primary" @click="submitSummary">Submit Summary</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { stringFormatDate } from '../../helpers/formatters'
import { ref, computed, watch } from '@vue/composition-api'
export default {
  components: {
    htmlEditor: () => import('@/components/forms/HTMLEditor')
  },
  props: {
    chapel: {
      type: Object,
      required: true
    },
    pidm: {
      type: String,
      required: true
    },
    term: {
      type: Object,
      required: true
    },
    tooltip: String
  },
  setup (props, { root, emit }) {
    const dialogActive = ref(false)
    const wordCount = ref(0)
    const summary = ref('')
    const summaryCount = ref(0)
    const summaryMinWords = computed(() => {
      if (props.chapel.term === '202060' || props.chapel.term === '202160') return 1000
      if (summaryCount.value < 5) return 250
      else if (summaryCount.value < 10) return 500
      else return 1000
    })

    watch(summary, () => {
      if (summary.value === '' || summary.value == null || summary.value === '<p></p>') {
        wordCount.value = 0
        return
      }
      const div = document.createElement('div')
      div.innerHTML = summary.value.replaceAll('</li>', ' </li>').replaceAll('</p>', ' </p>').replaceAll('</h', ' </h')
      let text = div.textContent || div.innerText || ''
      wordCount.value = text.match(/[\w\d’'-]+/gi).length || 0
    })

    const timeout = ref(null)
    watch(dialogActive, async (val) => {
      if (val) {
        // Check how many summaries this student has submitted
        // We are doing this here so it will check every time they open the summary dialog. This is just in case they do multiple summaries in a row without refreshing the page
        const { total } = await root.$feathers.service('chapel/summary').find({ query: { pidm: props.pidm, term: props.term.term, chapel: { $ne: props.chapel._id }, status: { $ne: 'Rejected' }, $limit: 0 } })
        summaryCount.value = total

        timeout.value = setInterval(() => {
          console.log('Activity Updated')
          root.$store.commit('updateActivity')
        }, 60000)
      } else {
        clearInterval(timeout.value)
      }
    })

    async function submitSummary () {
      if (wordCount.value >= summaryMinWords.value) {
        const chapel = props.chapel._id
        const { data } = await root.$feathers.service('chapel/student').find({ query: { pidm: props.pidm, term: props.term.term } })
        if (data.length > 0) {
          const { _id } = await root.$feathers.service('chapel/summary').create({
            pidm: props.pidm,
            term: props.term.term,
            user: data[0]._id,
            chapel,
            summary: summary.value,
            status: 'Pending'
          })
          // Check to see if this chapel is not already in their credit list; if it is update that one
          const creditObj = { credit: 0, chapel, date: new Date(), type: 'Summary', reason: 'Summary Submitted; Pending Review', summary: _id }
          let hasCredit = false
          for (let i = 0; i < data[0].credits.length; i++) {
            if (data[0].credits[i].chapel === props.chapel) {
              await root.$feathers.service('chapel/student').patch(data[0]._id, { ['credits.' + i]: creditObj })
              hasCredit = true
              break
            }
          }
          if (!hasCredit) {
            await root.$feathers.service('chapel/student').patch(data[0]._id, { $push: { credits: creditObj } })
          }
          root.$store.dispatch('main/snackbar', { color: 'success', text: 'Summary submitted successfully.' })
          emit('submitted')
          dialogActive.value = false
        } else {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Your ID was not found for this term in the chapel students database. This is probably a system error. Please contact the Chapel office or Technology Services for assistance.' })
        }
      }
    }

    return {
      dialogActive,
      wordCount,
      summary,
      summaryCount,
      summaryMinWords,
      submitSummary,
      stringFormatDate
    }
  }
}
</script>
